<template>
  <div class="service-appointment-time">
    <template v-if="isEditing">
      <h1 class="text-orange mb-4" style="font-size: 24px">
        {{ $t('appointments.editAppointment.title') }}
      </h1>
      <div
        class="alert alert-orange d-inline"
        v-html="$t('appointments.editAppointment.alertText')"
      ></div>
      <h3 class="text-orange mt-4 pt-2" style="font-size: 20px">
        {{ $t('appointments.editAppointment.subtitle') }}
      </h3>
    </template>

    <div class="row">
      <!-- Left side -->
      <div class="left-part col-12 col-lg-7">
        <!-- Date picker -->
        <div class="gray-box" style="height: 240px">
          <DateRangePicker
            v-model="selectedRange"
            :ranges="false"
            single-date-picker
            auto-apply
            :min-date="yesterday"
            class="header"
          >
            <template #input="{ startDate }">
              <CalendarIcon color="#999999" />
              {{ intlMonthYearDateFormat.format(startDate) }}
            </template>
          </DateRangePicker>

          <div class="day-picker">
            <div
              v-for="(value, day) of daysInWeek"
              :key="`${day}-${value.day}`"
              :class="{
                disabled: value.closed,
                selected: value.fullDate === selectedDay,
              }"
              @click="
                if (!value.closed) {
                  selectedDay = value;
                  selectedTimeSlot = null;
                }
              "
              class="day"
            >
              <div class="day-label">{{ day }}</div>
              <div class="day-value">{{ value.day }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right side -->
      <div class="right-part col-12 col-lg-5 order-first order-lg-last">
        <!-- Service info (Service provider) -->
        <div
          class="gray-box"
          v-if="$store.getters.serviceBookAppointment.service"
        >
          <div class="grid">
            <div class="left-part">
              <h4 class="title">
                {{ $store.getters.serviceSelectedService.name }}
              </h4>
              <!--<div class="person">
                <div class="image">
                  <img src="https://via.placeholder.com/41" />
                </div>
                <div class="name">Katarina James</div>
              </div>-->

              <div class="details mt-5">
                <div class="details-location">
                  {{ $store.getters.serviceSelectedService.description }}
                </div>
              </div>
            </div>
            <div class="right-part align-right">
              <div class="price-duration-container">
                <div class="duration">
                  <div>
                    <ClockIcon />
                    <span class="pt-1">
                      {{ $store.getters.serviceSelectedService.duration }}
                      Minuten
                    </span>
                  </div>
                </div>
                <div class="place-price">
                  <div class="price">
                    {{ $store.getters.serviceSelectedService.price | EUR }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Places left (retailer) -->
        <div class="gray-box" v-else>
          <div class="grid">
            <div class="left-part">
              <h4 class="title">
                {{ $store.getters.serviceCompanyDetails.companyName }}
              </h4>
              <!--<div class="person">
                <div class="image">
                  <img src="https://via.placeholder.com/41" />
                </div>
                <div class="name">Katarina James</div>
              </div>-->

              <div class="details">
                <div class="details-location">
                  {{ $store.getters.serviceCompanyDetails.description }}
                </div>
              </div>
            </div>
            <div class="right-part align-right">
              <div class="price-duration-container" v-if="placesLeft != null">
                <div class="place-price">
                  <div class="place">
                    ({{
                      $t('enduser.events.placeNLeft', {
                        n: placesLeft,
                      })
                    }})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Staff select -->
        <div
          class="gray-box staff-select"
          v-if="
            $store.getters.serviceType === 'service_provider' &&
            $store.getters.companyStaff
          "
        >
          <label style="color: ">Mitarbeiter</label>
          <SelectElement
            v-if="!appointmentData"
            :value="$store.getters.serviceBookAppointment.staffId"
            :options="staffOptions"
            @input="
              selectedTimeSlot = null;
              selectedStaff = $store.getters.companyStaff.find(
                i => i.staffId == $event
              );
              $store.commit('setServiceBookAppointmentPayload', {
                key: 'staffId',
                value: $event,
              });
            "
            search
          />
          <SelectElement
            v-else-if="staffOptions.length"
            :value="appointmentData.staffId"
            :options="staffOptions"
            disabled
          />
        </div>
      </div>
    </div>

    <!-- Time slots -->
    <div
      class="pick-time-slot"
      v-if="
        selectedDay &&
        ($store.getters.serviceType != 'service_provider' || selectedStaff)
      "
    >
      <div class="gray-box">
        <div class="header">
          <span class="header-label text-left">Uhrzeit auswahlen</span>
        </div>
        <div class="time-slots">
          <div
            class="time-slot"
            v-for="timeSlot in timeSlots"
            :key="generateUniqueKey(timeSlot.slot)"
            :class="{
              disabled: timeSlot.booked,
              selected: selectedTimeSlot === timeSlot.slot,
            }"
            @click="timeSlot.booked || (selectedTimeSlot = timeSlot)"
          >
            {{ timeSlot.slot }}
          </div>
        </div>
      </div>
    </div>

    <!--<div class="appointment-details grid faded">
      <div class="left-part">
        <MapElement />
      </div>
      <div class="right-part">
        <div class="title">Class Desrcription</div>
        <div>
          <p>
            Access to sufficient amounts of safe and nutritious food is key to
            sustaining life and promoting good health, but hungry and
            malnourished children have a daily fight for survival. While you
            can’t provide for every one of the world’s hungry children, you can
            make a difference for one child.
          </p>
          <p>
            Donating to a charity is an important decision. So when you’re
            passionate about a cause and want to make a difference, we encourage
            you to do your research.
          </p>
        </div>
      </div>
    </div>-->

    <div class="appointment-actions" style="margin-bottom: 5rem">
      <ButtonElement orange-secondary class="left" @click="$emit('cancel')">
        {{ $t('enduser.services.cancel') }}
      </ButtonElement>
      <div class="right">
        <ButtonElement
          orange
          @click="handleSubmit"
          :disabled="
            !selectedDay ||
            !selectedTimeSlot ||
            ($store.getters.serviceType === 'service_provider' &&
              !$store.getters.serviceBookAppointment.staffId)
          "
        >
          {{ $t('enduser.services.jetztTermin') }}
        </ButtonElement>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { intlDayFormat, intlMonthYearDateFormat } from '../../../config/intl';
import CalendarIcon from '../../../components/Svgs/Sidebar/CalendarIcon';
import ClockIcon from '../../../components/Svgs/ClockIcon';
import BellIcon from '../../../components/Svgs/BellIcon';
import MapElement from '../../../components/MapElement/MapElement';
import ButtonElement from '../../../components/FormControl/ButtonElement';
import store from '../../../store';
import { dateFormat } from '../../../utils/dateTimeHelpers';
import SelectElement from '@/components/FormControl/SelectElement/SelectElement.vue';

export default {
  setup(props) {
    const selectedStaff = ref(null);
    const selectedRange = ref({
      startDate: new Date(),
      endDate: new Date(),
    });
    const selectedDayInWeek = ref(null);
    const placesLeft = ref(null);

    onMounted(async () => {
      const companyId =
        props.appointmentData?.companyId ||
        store.getters.serviceBookAppointment.companyId;
      const serviceId =
        props.appointmentData?.service ||
        store.getters.serviceBookAppointment.service;

      let payload = {
        companyId,
      };

      if (store.getters.serviceType === 'service_provider') {
        payload['serviceId'] = serviceId;

        await store.dispatch('fetchCompanyStaff', {
          companyId,
          serviceId,
        });
      }

      await store.dispatch('fetchCompanyAppointments', payload);

      // For edit
      if (props.appointmentData) {
        selectedStaff.value = props.appointmentData.staffUser;
        store.commit('setServiceBookAppointmentPayload', {
          key: 'staffId',
          value: props.appointmentData.staffId,
        });
      }
    });

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const selectedDay = computed({
      get: () => store.getters.serviceBookAppointment.dateStart,
      set: value => {
        selectedDayInWeek.value = value.dayInWeek;
        store.commit('setServiceBookAppointmentPayload', {
          key: 'dateStart',
          value: value.fullDate,
        });
      },
    });

    const selectedTimeSlot = computed({
      get: () => {
        if (
          !store.getters.serviceBookAppointment.timeStart ||
          !store.getters.serviceBookAppointment.timeEnd
        ) {
          return null;
        }

        return `${store.getters.serviceBookAppointment.timeStart} - ${store.getters.serviceBookAppointment.timeEnd}`;
      },
      set: value => {
        placesLeft.value = value ? value.placesLeft : null;
        store.commit('setServiceBookAppointmentPayload', {
          key: 'timeStart',
          value: value ? value.slot.split(' - ')[0] : null,
        });
        store.commit('setServiceBookAppointmentPayload', {
          key: 'timeEnd',
          value: value ? value.slot.split(' - ')[1] : null,
        });
      },
    });

    return {
      placesLeft,
      yesterday,
      selectedStaff,
      selectedRange,
      selectedDay,
      selectedTimeSlot,
      selectedDayInWeek,
      intlMonthYearDateFormat,
    };
  },
  computed: {
    isEditing() {
      return !!this.appointmentData;
    },
    staffOptions() {
      return this.$store.getters.companyStaff.map(item => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.staffId,
      }));
    },
    daysInWeek() {
      let curr = new Date(this.selectedRange.startDate);
      let weekDays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
      let week = {};

      for (let i = 1; i <= 7; i++) {
        let first = curr.getDate() - curr.getDay() + i;
        let day = new Date(curr.setDate(first));
        let isInThePast =
          day.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
        week[weekDays[i - 1]] = {
          fullDate: dateFormat(day),
          day: intlDayFormat.format(day),
          dayInWeek: i,
          closed:
            isInThePast || !this.$store.getters.serviceCompanyIsWorking(i),
        };
      }

      return week;
    },
    timeSlots() {
      if (
        this.$store.getters.serviceCompanyDetails.type === 'service_provider'
      ) {
        return this.$store.getters.serviceCompanyTimeSlots(
          this.selectedDayInWeek,
          this.selectedDay,
          this.selectedStaff
        );
      } else {
        return this.$store.getters.availableCompanyAppointmentSlotsByDate(
          this.selectedDay
        );
      }
    },
  },
  methods: {
    generateUniqueKey(timeSlot) {
      return timeSlot.replaceAll(/[^A-Za-z0-9]/g, '-');
    },
    handleSubmit() {
      if (this.isEditing) {
        this.$emit('confirm-edit');
      } else {
        this.$emit('confirm');
      }
    },
  },
  components: {
    MapElement,
    CalendarIcon,
    DateRangePicker,
    ClockIcon,
    ButtonElement,
    BellIcon,
    SelectElement,
  },
  props: {
    appointmentData: {
      type: [Boolean, Object],
      required: false,
    },
  },
};
</script>

<style lang="scss">
.service-appointment-time {
  .grid {
    display: flex;

    .left-part {
      flex: 0 0 60%;
      padding-right: 1rem;
    }

    .right-part {
      flex: 0 0 40%;
    }
  }

  .align-right {
    text-align: right;
  }

  .gray-box {
    padding: 1rem;
    background-color: $hty-grey;
    border-radius: 10px;
    margin-bottom: 1rem;

    .title {
      color: $hty-orange;
      font-size: 1.5rem;
      line-height: 1.75rem;
    }

    .header {
      display: block;
      margin: 0 -1rem;
      padding: 0 1rem 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $hty-medium-grey;

      .header-label,
      .reportrange-text {
        display: flex;
        align-items: center;
        color: $hty-dark-grey;
        justify-content: center;
        background: transparent;
        cursor: pointer;
        padding: 0;
        border: none;
        width: auto;
        font-size: 1.25rem;

        &.text-left {
          text-align: left;
          justify-content: flex-start;
        }

        svg {
          margin-right: 8px;
        }
      }
    }

    .day-picker {
      display: flex;
      justify-content: space-between;

      .day {
        flex: 0 0 12%;
        background-color: white;
        border-radius: 10px;
        color: $hty-black;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        border: 1px solid transparent;
        transition: 0.3s ease-in-out;

        &:not(.disabled) {
          &:hover,
          &:active,
          &.selected {
            transform: translateY(-6px);
            border-color: $hty-orange;
            box-shadow: 6px 10px 20px 0 rgba($hty-orange, 0.06);
            color: $hty-orange;

            .day-label {
              border-bottom-color: $hty-orange;
            }
          }
        }

        &.disabled {
          color: $hty-dark-grey;
          cursor: not-allowed;
        }
      }

      .day-label,
      .day-value {
        padding: 0.8rem;
      }

      .day-label {
        font-size: 1.5rem;
        border-bottom: 1px solid $hty-grey-text;
      }

      .day-value {
        font-size: 1rem;
      }
    }
  }

  .person {
    display: flex;
    background-color: white;
    border-radius: 5px;
    box-shadow: 6px 10px 20px rgba(0, 0, 0, 0.03);
    padding: 6px 8px;
    align-items: center;

    .image {
      flex: 0 0 40px;
      height: 40px;
      border-radius: 5px;
      margin-right: 8px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }

  .details {
    font-size: 0.8rem;
    color: $hty-dark-grey;
    margin-top: 1rem;

    .details-title {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .staff-select {
    label {
      color: $hty-orange;
    }
  }

  .price-duration-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
  }

  .duration {
    flex: 1 1 100%;
    text-align: right;
    color: $hty-dark-grey;
    font-size: 0.75rem;

    div {
      display: inline-block;
      text-align: center;
    }

    span {
      display: block;
    }
  }

  .place-price {
    flex: 0 0 auto;
    font-size: 1.5rem;

    .place {
      font-size: 1.25rem;
      margin-bottom: 1rem;

      span {
        color: $hty-orange;
      }
    }
  }

  .time-slots {
    display: block;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    padding: 1rem 0;

    .time-slot {
      display: inline-block;
      height: 3rem;
      line-height: 3rem;
      background-color: white;
      border-radius: 5px;
      box-shadow: 6px 10px 20px rgba(0, 0, 0, 0.03);
      overflow: hidden;
      margin-right: 1rem;
      padding: 0 1rem;
      color: $hty-dark-grey;
      font-size: 1.25rem;
      cursor: pointer;
      border: 1px solid transparent;
      transition: 0.3s ease-in-out;

      &:not(.disabled) {
        &:hover,
        &:active,
        &.selected {
          transform: translateY(-6px);
          border-color: $hty-orange;
          box-shadow: 6px 10px 20px rgba($hty-orange, 0.06);
          color: $hty-orange;
        }
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .time-slot-empty {
      display: block;
      color: $hty-dark-grey;
      font-size: 1.25rem;
      text-align: center;
    }
  }

  .appointment-details {
    p {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .map-holder {
      height: 280px;
    }
  }

  .appointment-actions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;

    .left,
    .right {
      flex: 0 0 auto;
      display: flex;
    }

    .left button {
      margin-right: 1rem;
    }

    .right button {
      margin-left: 1rem;
    }
  }

  .faded {
    opacity: 0.4;
  }
}
</style>
