var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"service-appointment-time"},[(_vm.isEditing)?[_c('h1',{staticClass:"text-orange mb-4",staticStyle:{"font-size":"24px"}},[_vm._v(" "+_vm._s(_vm.$t('appointments.editAppointment.title'))+" ")]),_c('div',{staticClass:"alert alert-orange d-inline",domProps:{"innerHTML":_vm._s(_vm.$t('appointments.editAppointment.alertText'))}}),_c('h3',{staticClass:"text-orange mt-4 pt-2",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.$t('appointments.editAppointment.subtitle'))+" ")])]:_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"left-part col-12 col-lg-7"},[_c('div',{staticClass:"gray-box",staticStyle:{"height":"240px"}},[_c('DateRangePicker',{staticClass:"header",attrs:{"ranges":false,"single-date-picker":"","auto-apply":"","min-date":_vm.yesterday},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var startDate = ref.startDate;
return [_c('CalendarIcon',{attrs:{"color":"#999999"}}),_vm._v(" "+_vm._s(_vm.intlMonthYearDateFormat.format(startDate))+" ")]}}]),model:{value:(_vm.selectedRange),callback:function ($$v) {_vm.selectedRange=$$v},expression:"selectedRange"}}),_c('div',{staticClass:"day-picker"},_vm._l((_vm.daysInWeek),function(value,day){return _c('div',{key:(day + "-" + (value.day)),staticClass:"day",class:{
              disabled: value.closed,
              selected: value.fullDate === _vm.selectedDay,
            },on:{"click":function($event){if (!value.closed) {
                _vm.selectedDay = value;
                _vm.selectedTimeSlot = null;
              }}}},[_c('div',{staticClass:"day-label"},[_vm._v(_vm._s(day))]),_c('div',{staticClass:"day-value"},[_vm._v(_vm._s(value.day))])])}),0)],1)]),_c('div',{staticClass:"right-part col-12 col-lg-5 order-first order-lg-last"},[(_vm.$store.getters.serviceBookAppointment.service)?_c('div',{staticClass:"gray-box"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"left-part"},[_c('h4',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$store.getters.serviceSelectedService.name)+" ")]),_c('div',{staticClass:"details mt-5"},[_c('div',{staticClass:"details-location"},[_vm._v(" "+_vm._s(_vm.$store.getters.serviceSelectedService.description)+" ")])])]),_c('div',{staticClass:"right-part align-right"},[_c('div',{staticClass:"price-duration-container"},[_c('div',{staticClass:"duration"},[_c('div',[_c('ClockIcon'),_c('span',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.$store.getters.serviceSelectedService.duration)+" Minuten ")])],1)]),_c('div',{staticClass:"place-price"},[_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm._f("EUR")(_vm.$store.getters.serviceSelectedService.price))+" ")])])])])])]):_c('div',{staticClass:"gray-box"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"left-part"},[_c('h4',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$store.getters.serviceCompanyDetails.companyName)+" ")]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"details-location"},[_vm._v(" "+_vm._s(_vm.$store.getters.serviceCompanyDetails.description)+" ")])])]),_c('div',{staticClass:"right-part align-right"},[(_vm.placesLeft != null)?_c('div',{staticClass:"price-duration-container"},[_c('div',{staticClass:"place-price"},[_c('div',{staticClass:"place"},[_vm._v(" ("+_vm._s(_vm.$t('enduser.events.placeNLeft', { n: _vm.placesLeft, }))+") ")])])]):_vm._e()])])]),(
          _vm.$store.getters.serviceType === 'service_provider' &&
          _vm.$store.getters.companyStaff
        )?_c('div',{staticClass:"gray-box staff-select"},[_c('label',{staticStyle:{"color":""}},[_vm._v("Mitarbeiter")]),(!_vm.appointmentData)?_c('SelectElement',{attrs:{"value":_vm.$store.getters.serviceBookAppointment.staffId,"options":_vm.staffOptions,"search":""},on:{"input":function($event){_vm.selectedTimeSlot = null;
            _vm.selectedStaff = _vm.$store.getters.companyStaff.find(
              function (i) { return i.staffId == $event; }
            );
            _vm.$store.commit('setServiceBookAppointmentPayload', {
              key: 'staffId',
              value: $event,
            });}}}):(_vm.staffOptions.length)?_c('SelectElement',{attrs:{"value":_vm.appointmentData.staffId,"options":_vm.staffOptions,"disabled":""}}):_vm._e()],1):_vm._e()])]),(
      _vm.selectedDay &&
      (_vm.$store.getters.serviceType != 'service_provider' || _vm.selectedStaff)
    )?_c('div',{staticClass:"pick-time-slot"},[_c('div',{staticClass:"gray-box"},[_vm._m(0),_c('div',{staticClass:"time-slots"},_vm._l((_vm.timeSlots),function(timeSlot){return _c('div',{key:_vm.generateUniqueKey(timeSlot.slot),staticClass:"time-slot",class:{
            disabled: timeSlot.booked,
            selected: _vm.selectedTimeSlot === timeSlot.slot,
          },on:{"click":function($event){timeSlot.booked || (_vm.selectedTimeSlot = timeSlot)}}},[_vm._v(" "+_vm._s(timeSlot.slot)+" ")])}),0)])]):_vm._e(),_c('div',{staticClass:"appointment-actions",staticStyle:{"margin-bottom":"5rem"}},[_c('ButtonElement',{staticClass:"left",attrs:{"orange-secondary":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('enduser.services.cancel'))+" ")]),_c('div',{staticClass:"right"},[_c('ButtonElement',{attrs:{"orange":"","disabled":!_vm.selectedDay ||
          !_vm.selectedTimeSlot ||
          (_vm.$store.getters.serviceType === 'service_provider' &&
            !_vm.$store.getters.serviceBookAppointment.staffId)},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('enduser.services.jetztTermin'))+" ")])],1)],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('span',{staticClass:"header-label text-left"},[_vm._v("Uhrzeit auswahlen")])])}]

export { render, staticRenderFns }