<template>
  <div class="book-appointment">
    <BookAppointmentServices
      v-if="appointmentStep === 1"
      @cancel="handleCancel"
      @confirm="appointmentStep++"
    />
    <BookAppointmentTime
      v-else-if="appointmentStep === 2"
      :appointment-data="appointmentData"
      @cancel="handleCancel"
      @confirm="handleSubmit"
      @confirm-edit="editModal = true"
    />

    <ResponseAlert
      v-if="$store.getters.errorResponse.action == 'appointmentEdit'"
      :response="$store.getters.errorResponse.data"
    ></ResponseAlert>

    <!-- Confirmation modal for edit -->
    <Modal
      :open="editModal"
      @backdropClick="
        editModal = false;
        editModalReadMore = false;
      "
    >
      <ConfirmationModal
        style="max-width: 550px"
        @cancel="
          editModal = false;
          editModalReadMore = false;
        "
        @approve="handleEditSubmit"
        color-approve-button="orange"
        :text-approve-button="$t('enduser.calendar.confirm')"
      >
        <template #header>
          <span class="text-orange">
            {{ $t('appointments.postponeAppointment.modalTitle') }}
          </span>
        </template>
        <template #default>
          <div>
            <b>{{ $t('appointments.postponeAppointment.modalText1') }}</b>
          </div>
          <a
            v-if="!editModalReadMore"
            href="#"
            @click="editModalReadMore = true"
            class="text-orange"
          >
            {{ $t('appointments.postponeAppointment.modalReadMore') }}
          </a>
          <template v-if="editModalReadMore">
            <div class="border-top my-3"></div>
            {{ $t('appointments.postponeAppointment.modalText2') }}
          </template>
        </template>
      </ConfirmationModal>
    </Modal>
  </div>
</template>

<script>
import BookAppointmentServices from './BookAppointmentServices';
import BookAppointmentTime from './BookAppointmentTime';
import Modal from '@/components/Modal/Modal.vue';
import ConfirmationModal from '@/components/Modal/ConfirmationModal.vue';

export default {
  data() {
    return {
      appointmentData: false,
      appointmentStep: 1,
      editModal: false,
      editModalReadMore: false,
    };
  },
  created() {
    this.$store.commit('servicesClearBooking');
    this.$store.commit('setServiceBookAppointmentPayload', {
      key: 'companyId',
      value: this.id.toString(),
    });
    this.$store.commit('setServiceBookAppointmentPayload', {
      key: 'clientId',
      value: this.$store.getters.userInfo.id.toString(),
    });
    this.$store.dispatch('fetchServiceCompanyDetails', this.id).then(() => {
      if (this.$store.getters.serviceCompanyDetails.type == 'retailer') {
        this.appointmentStep = 2;
      }
    });
    this.$store.dispatch('fetchCompanyLocations');

    if (this.$route.query.appointment_id) {
      this.$store
        .dispatch('fetchAppointmentById', {
          appointmentId: this.$route.query.appointment_id,
          params: {
            company_id: this.$route.params.id,
          },
        })
        .then(() => {
          this.appointmentData = this.$store.getters.appointmentById;

          if (this.isAppointmentInPast(this.appointmentData)) {
            this.$router.replace({ name: 'Calendar' });
            this.$store.commit('setToastAlert', {
              text: 'Der Termin kann nicht bearbeitet werden.',
            });
          }

          if (this.appointmentData.serviceData) {
            this.$store.commit(
              'setServiceSelectedService',
              this.appointmentData.serviceData
            );
            this.$store.commit('setServiceBookAppointmentPayload', {
              key: 'service',
              value: this.appointmentData.serviceData.id,
            });
          }

          this.appointmentStep = 2;
        })
        .catch(() => {
          this.$router.replace({ name: 'Calendar' });
          this.$store.commit('setToastAlert', {
            text: 'Termin nicht gefunden.',
          });
        });
    }
  },
  methods: {
    handleSubmit() {
      this.$store
        .dispatch(
          'createAppointment',
          this.$store.getters.serviceBookAppointment
        )
        .then(() => {
          this.$store.commit('servicesClearBooking');
          this.$router.push({ name: 'Calendar' });
        });
    },

    handleEditSubmit() {
      this.editModal = false;
      this.$store
        .dispatch('updateAppointment', {
          id: this.appointmentData.id,
          payload: this.$store.getters.serviceBookAppointment,
        })
        .then(() => {
          this.$store.commit('servicesClearBooking');
          this.$router.push({ name: 'Calendar' });
        });
    },

    handleCancel() {
      this.$store.commit('servicesClearBooking');
      this.$router.push({
        name:
          this.$store.getters.serviceType === 'retailer'
            ? 'Products'
            : 'Services',
      });
    },

    isAppointmentInPast(apt) {
      console.log('aptttt', apt);
      const aptDate = new Date(`${apt.dateStart}T${apt.timeStart}`);
      return aptDate <= new Date();
    },
  },
  components: {
    BookAppointmentServices,
    BookAppointmentTime,
    Modal,
    ConfirmationModal,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: [Number, String],
  },
};
</script>

<style lang="scss" scoped>
.company-settings-container {
  padding: 1.5rem 2rem;
  height: calc(100vh - 110px);
  overflow: auto;
}
</style>
