<template>
  <div class="map-holder mt-6">
    <LMap class="map" :zoom="zoom" :center="center">
      <LTileLayer :url="url"></LTileLayer>
    </LMap>
  </div>
</template>

<script>
import { LMap, LTileLayer } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  props: {
    center: {
      type: Array,
      default: () => [47.41322, -1.219482],
    },
    zoom: {
      type: Number,
      default: 3,
    },
    url: {
      type: String,
      default: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    },
  },
  components: {
    LMap,
    LTileLayer,
  },
};
</script>

<style>
.map-holder {
  height: 350px;
  overflow: hidden;
}
.map {
  height: 100%;
  width: 100%;
}
</style>
